import { Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik'
import React from 'react'
import { IApiModelView } from '../../apiModels/apiModel'

const isArray = (val: any) => {
  return Array.isArray(val)
}

const isObject = (val: any) => {
  return typeof val === 'object' && !Array.isArray(val) && val !== null
}

interface IProps {
  model?: IApiModelView
}

interface IOmnikEncoders<Values> {
  encodeValues?: (values: Values) => Values
  decodeValues?: (values: Values) => Values
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class Omnik<Values = object> extends React.Component<IProps & FormikConfig<Values>, {}> {
  private initialValues: FormikValues = {}

  private formikProps: FormikConfig<Values>

  constructor(props: IProps & FormikConfig<Values>) {
    super(props)

    // Set a copy of our initialValues and create a new one without null
    this.initialValues = props.initialValues
    // Initialize and create the formik props
    this.formikProps = this.initializeFormikProps(props)
  }

  public render() {
    const { formikProps } = this

    formikProps.initialValues = this.encodeValues(this.props.initialValues)

    if (this.props.onReset) {
      formikProps.onReset = this.handleReset
    }

    formikProps.onSubmit = this.handleSubmit

    return <Formik {...formikProps} />
  }

  public handleSubmit = (values: Values, formikBag: FormikHelpers<Values> & IOmnikEncoders<Values>) => {
    const { encodeValues, decodeValues } = this
    const { onSubmit } = this.props

    // Check if we need to reset an empty string with a matching null value
    // in our stored initialValues
    const newValues = this.decodeValues(values)

    formikBag.encodeValues = encodeValues
    formikBag.decodeValues = decodeValues

    onSubmit(newValues, formikBag)
  }

  public handleReset = (values: Values, formikBag: FormikHelpers<Values> & IOmnikEncoders<Values>) => {
    const { encodeValues, decodeValues } = this
    const { onReset } = this.props

    // Check if we need to reset an empty string with a matching null value
    // in our stored initialValues
    const newValues = this.decodeValues(values)

    formikBag.encodeValues = encodeValues
    formikBag.decodeValues = decodeValues

    onReset && onReset(newValues, formikBag)
  }

  public encodeValues = (values: Values) => {
    const { encodeNullValues, encodeArrayValues } = this
    let newValues = Object.assign({}, values)
    const allowNull = ['webTitle', 'parentProviderId']

    // First encode null values to empty strings, except the
    // values whos key is listed in the allowNull array.
    newValues = encodeNullValues(newValues, allowNull)

    // Then stringify arrays.
    newValues = encodeArrayValues(newValues)

    return newValues
  }

  public decodeValues = (values: Values) => {
    const { decodeNullValues, decodeArrayValues } = this
    let newValues = Object.assign({}, values)

    newValues = decodeNullValues(newValues)
    newValues = decodeArrayValues(newValues)

    return newValues
  }

  /**
   * Encodes values consisting of null with an empty stringg ('').
   * @param allowNull An array with keys to exempt from getting its value encoded to an empty string.
   */
  private encodeNullValues = (values: Values, allowNull: Array<string> = []) => {
    const { encodeNullValues } = this
    const newValues = Object.assign({}, values)

    // Iterate each property and check for a null value - then reset to
    // empty string if null is found - iterate recursivly for objects.
    Object.keys(newValues).forEach((key) => {
      if (allowNull.indexOf(key) < 0) {
        const value = newValues[key]
        if (value === null) {
          newValues[key] = ''
        } else if (isObject(value)) {
          newValues[key] = encodeNullValues(value)
        }
      }
    })

    return newValues
  }

  private decodeNullValues = (values: Values, matchValues: FormikValues = this.initialValues) => {
    const { decodeNullValues } = this
    const newValues = Object.assign({}, values)

    Object.keys(newValues).forEach((key) => {
      const value = newValues[key]
      const matchValue = matchValues[key]

      // If we get an empty string - then check in matchValues for a null value
      // to place on key instead of the empty string
      if (typeof value === 'string' && !value && matchValue === null) {
        newValues[key] = null
      } else {
        if (isObject(value)) {
          newValues[key] = decodeNullValues(value, matchValue)
        }
      }
    })

    return newValues
  }

  private encodeArrayValues = (values: Values) => {
    const { encodeArrayValues } = this
    const newValues = Object.assign({}, values)

    // Iterate the given values and look for arrays to stringify
    Object.keys(newValues).forEach((key) => {
      const value = newValues[key]

      if (isArray(value)) {
        // newValues[key] = JSON.stringify(value)
      } else if (isObject(value)) {
        newValues[key] = encodeArrayValues(value)
      }
    })

    return newValues
  }

  private decodeArrayValues = (values: Values, matchValues: FormikValues = this.initialValues) => {
    const ignoreDecoding = ['paymentGateways']

    const { decodeArrayValues } = this
    const newValues = Object.assign({}, values)

    Object.keys(newValues).forEach((key) => {
      const value = newValues[key]
      const matchValue = matchValues[key]

      if (ignoreDecoding.includes(key)) {
        newValues[key] = value
        return
      }

      if (isArray(matchValue)) {
        // newValues[key] = JSON.parse(value)
      } else if (isObject(value) && value !== null) {
        newValues[key] = decodeArrayValues(value, matchValues[key])
      }
    })

    return newValues
  }

  private initializeFormikProps = (props: IProps & FormikConfig<Values>) => {
    const { encodeValues } = this
    const formikProps = { ...props }
    const { initialValues } = props

    formikProps.initialValues = encodeValues(initialValues)

    // Delete the model from formikProps
    delete formikProps.model

    return formikProps
  }
}

export default Omnik
